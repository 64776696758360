import React, {useCallback} from 'react'
import {CustomView, isBrowser, BrowserView} from 'react-device-detect'
import Classes from './MassMailer.css'

const GMAIL_SECURITY_INFO_LINK='https://myaccount.google.com/lesssecureapps';
const MASSMAILER_BLOGPOST_LINK ='https://coffeefromme.blogspot.com/2017/01/free-mass-email.html';
const DOWNLOAD_HOME = "download/massmailer/2.0";

const massMailer = () =>{

    return (
        <section class="product_section mass_mailer_product_section">
        <h2>RATIONALMINDS MASSMAILER</h2>
        <div>
          <p class="product_text">Send bulk emails addressed individually for free. 
          Emails are used by everybody and for special occasions or  limited marketting, 
          its impactful to send mails to individuals with personal touch.</p>

          <p class="product_text">There are tons of paid services available to provide this service but none of them are fully free.</p>

          <p class="product_text">RationalMassMailer is a very simple to use tool to send emails and leave your impact. 
          This service works for gmail (One has to lower security to allow third party apps to access gmail. Click <a href={GMAIL_SECURITY_INFO_LINK}>here</a> to find out about your gmail access security for unregisreted third party apps) and yahoo accounts. 
          Gmail and Yahoo restricts number of individual mails from its public accounts for a day, this limit is approximately 90 emails in a given day. 
          RationalMassMailer allows you to pick your desired send rate, to remain under the radar.</p>

          <p class="product_text">For more details visit the RationalMassMailer on <a href={MASSMAILER_BLOGPOST_LINK}>this blogpost</a>.</p>
        </div>
        <BrowserView>
        <div class="mass_mailer_download_options"><p>Download Rational Mass E-mail Sender</p></div>
        <div class="mass_mailer_download_options">
          <a href={DOWNLOAD_HOME+"/RationalMassMailer.exe"}><div class = "mass_mailer_download_windows"/></a>
          <a href={DOWNLOAD_HOME+"/RationalMassMailer.pkg"}><div class = "mass_mailer_download_mac" /></a>
          <a href={DOWNLOAD_HOME+ "/RationalMassMailer.jar"}><div class = "mass_mailer_download_linux" /></a>
        </div>
        </BrowserView>
        <CustomView class="global_download_links_notation_for_non_desktop" condition={isBrowser === false}>
          <p>Visit this site on desktop to download MassMailer</p>
        </CustomView>
      </section>
    )
}

export default massMailer;