import React from 'react';

const dateParser = () =>{
    return(
        <section class="product_section">
        <h2>DATE PARSER</h2>
        <div>
          <p class="product_text">
            Date parser is a free library for parsing date and time from simple text. 
            Date Parser not only identifies the date and time but also converts it into a valid java date object. 
            Date Parser can identify dates in any prescribed java date format in the most efficient manner using a linear identification algorithm. 
            It also tells about the pattern, length, and position of the date fragments in the given text. 
            Date parser's application includes log parsing, unstructured text file parsing to extract meaningful date-time information.
          </p>

          <p class="product_text">
            This library also includes a powerful regular expression generator, 
            which can generate an optimized expression to filter text file or log file containing date in a given range 
            of date-time up to seconds in any format.
          </p>

          <p class="product_text">
            Pluto uses these powerful features for log analysis.
          </p>

          <p><a href="http://coffeefromme.blogspot.com/2015/10/utility-to-extract-date-from-text-with.html" title="blogger" target="_blank" class="external_links">Follow this link at blogger </a></p>
          <p><a href="https://github.com/vbhavsingh/DateParser" title="github" target="_blank" class="external_links">Follow and contribute at GitHub</a></p>
        </div>
      </section>
    )
} 

export default dateParser;