import React from 'react';

const calculators = () =>{
    return(
        <section class="product_section">
        <h2>RATIONALMINDS CALCULATORS</h2>
        <div class="product_text">
            <p>
                An attempt to bring all sorts of calculators under a single roof. 
                Rationalminds calculators present all browser-based, private calculators. 
                We solved some challenging equations to bring this hassle-free, user-friendly tool for you.
            </p>
            <p>
                Currently, our star attraction mortgage refinances calculator which tells you about your savings, 
                ways to achieve the same savings in case refinancing is not an option, with minimum possible inputs from you. 
            </p>
            <p>
                Try out the calculators at <a href="https://calci.rationalminds.net">https://calci.rationalminds.net</a>
            </p>
        </div>
        </section>
    )
}

export default calculators;