import React from 'react';
import Pluto from './components/Pluto/Pluto'
import MassMailer from './components/MassMailer/MassMailer'
import DateParser from './components/DateParser/DateParser'
import Calculators from './components/Calculators/Calculators'
import './App.css';
import banner from './images/banner.png'
import logo from './images/logo_50.png'

function App() {
  return (
    <div class="container"> 
      <header> <img src={logo} alt="" width="50" height="50" class="logoimg"/>
        <h4 class="logo">RATIONALMINDS</h4>
        <nav>
          <ul>
            <li><a href="#hero">HOME</a></li>
            <li><a href="#products">PRODUCTS</a></li>
            <li><a href="#contact">CONTACT</a></li>
          </ul>
        </nav>
      </header>

    <section class="hero" id="hero"> <img src={banner}  alt="" style={{'max-width':'100%','max-height':'100%'}}/> </section>

    <section class="about" id="about">
      <h2 class="hidden">About</h2>
      <p class="text_column">Rational minds produce simple tools that help in daily operations. Our focus is on neat, small & smart solutions. We believe in keeping things as simple as possible and to produce something that is just to the point, instead of gas guzzling machines. Our tools are easy to deploy and maintain and do not require a specialized environment with huge hardware. We believe in green IT, and for that, we must use the resources which are at our disposal effectively, before mining for more new resources.</p>
      <p class="text_column">The tools need minimal or zero configuration, thus reducing the expertise required to maintain and sustain the environment. The tools are to ease out the operations, rather than bringing their own piece of a nuisance with them. The philosophy is, I should care about my car, not the jack, the jack should work fine whenever I need it.</p>
      <p class="text_column">The human resources should have more and more time to innovate, rather than doing repetitive jobs. Organizations need to expend human capital to harness the power of mind towards innovation, making things better for every tomorrow. Our goal is to help organizations to free their mind and run in the directions, where they had set their sails for. The tools that we produce are free to use for an undisclosed time. One can simply download the tools, install and use as per one's requirement. Please check the license terms associated with every tool and utility.</p>
    </section>


  
    <section class="banner">
      <h2 class="parallax">RATIONALMINDS SOFTWARES</h2>
      <p class="parallax_description">Optimizing IT Operations</p>
    </section>

    <Pluto/>

    <MassMailer/>
    
    <DateParser/>

    <Calculators/>

  <footer> 
   <section class="product_section">
   <h2 id="contact">CONTACT US</h2>
   <div class="footer_column">
    <div>Address: 12655 morningpark cir, Alpharetta, GA, USA 30004</div>
    <div>Email: contact@rationalminds.net</div>
    <div>Phone : +16787758180</div>
    </div>
   </section>
  </footer>
  <div class="copyright">&copy;2020 - <strong>RATIONAMINDS</strong></div>
</div>
  );
}

export default App;
