import React from 'react'
import Classes from './Pluto.css'
import {CustomView, isBrowser, isMobile, isTablet, isIPad13}  from 'react-device-detect'


const pluto = () => {
    return (
        <section class="product_section pluto_product_section">
        <h2 id="products"></h2>
        <h2>RATIONALMINDS PLUTO</h2>
        <div>
        <span>{isIPad13}</span>
          <p class="product_text"> Pluto enables log search on distributed environment from single interface. Just drop the safe and secure Charon jars as per instructions on nodes where search is to be done, and all is set. Pluto does not store, index, export, import logs to anywhere else, you will be just able to search the logs present on particular node or server. Pluto doesn't do analytical search or history creation. It is just what many people who engage in day-to-day IT operations require. Pluto is tier 1 & 2 support level tool who support, troubleshoot applications as their daily job. Instead of looking for current logs by logging into servers or using any command line interface throwing dumb results, people can look into results sorted by date in the time zones they want. Pluto is very simple to install and use. It requires very simple hardware and simple java container to run (preferably Apache Tomcat®) to run and don't take any configuration rather than installing agents and creating users to whom you want give access to this tool. The agents come with well-established default parameters which work. It's ready to use as soon you install it. It requires almost no training to use the tool as interface is very simple, predictable and looks obvious everywhere. </p>
          <p class="product_text">Pluto version 5.0 now comes with powerful algorithm to search log files on time ranges to the accuracy of a second. This facility is very useful for support/ devops teams to find what they are looking for, from a pile of log files scattered all over network.</p>
          <p class="product_text">The results are quick as tool uses parallel computation everywhere to gather and compile the results. Users can get results in their time zone, system has capability to identify dates in correct time zones and converts them to desired time zones (currently five time zones are supported), this helps people to put results in right time context thus easing out chronological analysis. Pluto also lists out the non-dated logs into separate tab, to keep results separate.</p>
          <p class="product_text"> Pluto has feedback mechanism too, when a search is made tool lists out all the fault, restrictions, limitations that it faced while gathering and compiling the result. Several restrictions are engrained into system from security perspective. Number of concurrent searches, size of data set etc.. is limited to an extent to avoid any issues on the guest and host servers. </p>
          <p><a href="http://coffeefromme.blogspot.com/2017/07/pluto-log-search-tool.html" target="_blank" title="blogger" target="_blank" class="external_links">Click here to learn more</a></p>
          <p><a href="https://rm-pluto.herokuapp.com/pluto" target="_blank" title="demo" target="_blank" class="external_links">Click here to explore live demo of Pluto. The data in demo is not real and is generated randomly. </a></p>
        </div>
        <CustomView condition={isBrowser && !isIPad13}>
          <div class="pluto_video_download_article_desktop pluto_video_desktop">
            <object height="100%" width="100%" data="https://www.youtube.com/embed/eOtRh3uceCI"/>
          </div>         
          <div class="pluto_download_div"> 
            <a href="http://rationalminds.net/download/pluto/5.0/pluto.war" data-role="none">
              <span class="pluto_download_btn">Download 5.0</span>
            </a> 
            <span class="downloadConditions">
              <a href="http://www.binpress.com/license/view/l/a33a806553ad7035dd79f7aa207fa101" target="_blank" class="external_links">Terms & Conditions</a>
            </span> 
          </div>
        </CustomView>

        <CustomView condition={isMobile || isTablet || isIPad13}>
          <div class="pluto_video_download_article_non_desktop">
            <object height="100%" width="100%" data="https://www.youtube.com/embed/eOtRh3uceCI"/>
          </div>         
          <div class="global_download_links_notation_for_non_desktop">
          <p>Visit this site on desktop to download Pluto</p>
          </div>
        </CustomView>

      </section>
    )
}

export default pluto;
